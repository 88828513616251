<template>
    <region-wrapper>
        <item-header :title="t('profile.profile')"/>
        <div class="profile-form">
            <a-form layout="vertical">
                <a-form-item :label="t('profile.email')">
                    <Input :value="profileForm.email" :placeholder="t('profile.inputEmail')" :readonly='true'/>
                </a-form-item>
                <div class="item-wrapper">
                    <a-form-item :label="t('profile.name')">
                        <Input :value="profileForm.name" :readonly='true'/>
                        <router-link to="/user/change-name" class="edit">
                        <img src="@/assets/icons/edit.png" alt="editButton">
                        <span>{{t('profile.edit')}}</span>
                    </router-link>
                    </a-form-item>
                </div>
                <div class="item-wrapper">
                    <a-form-item label="Password">
                        <Input :value="profileForm.password" :placeholder="t('profile.inputPassword')" type='password' :eye='false' :readonly='true'/>
                        <router-link to="/user/change-password" class="edit">
                        <img src="@/assets/icons/edit.png" alt="editButton">
                        <span>{{t('profile.edit')}}</span>
                    </router-link>
                    </a-form-item>
                </div>
            </a-form>
        </div>
    </region-wrapper>
</template>
<script lang='ts'>
import importComponents from '@/utils/import-components';
import { computed, defineComponent } from 'vue';
import RegionWrapper from '@/components/common/RegionWrapper.vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import {
  Form, Button,
} from 'ant-design-vue';
import { useStore } from 'vuex';
import Input from '@/components/common/Input.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Profile',
  components: {
    ItemHeader,
    RegionWrapper,
    Input,
    ...importComponents(
      Form,
      Form.Item,
      Button,
    ),
  },
  setup() {
    const { t } = useI18n();
    const { state } = useStore();
    // 注册去掉姓名，所以没进入过个人中心修改名字的，取邮箱，修改后头部取姓
    const profileForm = computed(() => {
      const {
        userEmail,
        userLastName,
        userFirstName,
      } = state.userInfo;

      return {
        email: userEmail,
        name: userLastName === null ? '' : `${userFirstName} ${userLastName}`,
        password: '********',
      };
    });
    return {
      profileForm,
      t,
    };
  },
});
</script>
<style lang='scss' scoped>
@import '@/assets/styles/mixin.scss';
@import '@/assets/styles/variables.scss';
:deep(header){
    h2{
        border-bottom: 1px solid#EDEDED;
        padding-bottom: 21px;
        margin-top: 20px;
        margin-bottom: 43px;
    }
}
.profile-form{
    margin-bottom: 140px;
    :deep(.ant-form){
        .ant-row.ant-form-item{
            padding: 0;
            margin-bottom: 21px;
            .ant-col{
                padding: 0px;
                label{
                    font-size: 14px;
                    font-weight: 400;
                    color: $font-color;
                    line-height: 11px;
                }
            }
            .ant-form-item-control-wrapper{
                .ant-form-item-control
                .ant-form-item-children{
                    display: flex;
                    align-items: center;
                    .input-style{
                        font-size: 12px;
                        color: rgba(28, 28, 28, 0.5);
                    }
                    .edit{
                        display: flex;
                        align-items: flex-end;
                        background: #fff;
                        border: none;
                        outline: none;
                        cursor: pointer;
                        margin-left: 14px;
                        img{
                            width: 14px;
                            height: 14px;
                            margin-bottom: 1px;
                        }
                        span{
                            font-size: 12px;
                            font-weight: 500;
                            color: $theme-color;
                            line-height: 12px;
                            padding: 0 6px;
                        }
                    }
                }
            }
        }
    }
}
</style>
