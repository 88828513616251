
import importComponents from '@/utils/import-components';
import { computed, defineComponent } from 'vue';
import RegionWrapper from '@/components/common/RegionWrapper.vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import {
  Form, Button,
} from 'ant-design-vue';
import { useStore } from 'vuex';
import Input from '@/components/common/Input.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Profile',
  components: {
    ItemHeader,
    RegionWrapper,
    Input,
    ...importComponents(
      Form,
      Form.Item,
      Button,
    ),
  },
  setup() {
    const { t } = useI18n();
    const { state } = useStore();
    // 注册去掉姓名，所以没进入过个人中心修改名字的，取邮箱，修改后头部取姓
    const profileForm = computed(() => {
      const {
        userEmail,
        userLastName,
        userFirstName,
      } = state.userInfo;

      return {
        email: userEmail,
        name: userLastName === null ? '' : `${userFirstName} ${userLastName}`,
        password: '********',
      };
    });
    return {
      profileForm,
      t,
    };
  },
});
